<template>
  <div class="ErrorAddRecord" style="margin: -15px -15px">
    <div class="ErrorAdd_left">
      <div class="ErrorAdd_module">模块</div>
      <a-menu style="width: 100%" v-model="selectId" mode="inline">
        <a-menu-item
            v-for="(item, index) in templateList"
            @click="selectStatus(item.id,item.name)"
            :key="item.id"
        >
          {{ item.name }}
        </a-menu-item>
      </a-menu>
      <div style="display: flex;justify-content: right;padding: 5px">
        <a-pagination
            size="small"
            v-model="temPosition.pageNum"
            :total="temPosition.total"
            @change="menuPageChange"/>
      </div>
    </div>
    <div class="ErrorAdd_right">
      <div class="ErrorAdd_right_top">
        <div class="ErrorAdd_right_title">用户每日添加错题数量</div>
        <div>
          <div class="top_cont">
            <div class="EduRec-top-input">
              <span>日期：</span>
              <a-range-picker v-model="datePicker" style="margin-right: 15px;width: 220px" @change="onChange" />
            </div>
            <div class="EduRec-top-input">
              <a-button style="margin-right: 20px" @click="exportModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出该模块
              </a-button>
              <a-button  @click="exportAllModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出全部模块
              </a-button>
            </div>
          </div>
        </div>
        <div class="activity-table">
          <a-table
              @change="orderChange"
              :rowKey="record=>record.id"
              :pagination="false"
              :columns="columns"
              :data-source="data">
          <span slot="location" slot-scope="text, record">
          </span>
          </a-table>
          <!--分页-->
          <div class="pageView">
          <span class="pageLeft">共 {{ pagination.total }} 条记录 第 {{
              pagination.current
            }} / {{ Math.ceil(pagination.total / pagination.pageSize) }} 页</span>
            <a-pagination v-model="pagination.current"
                          :total="pagination.total"
                          :page-size-options="pageSizeOptions"
                          :page-size.sync="pagination.pageSize"
                          show-size-changer show-quick-jumper
                          @showSizeChange="showSizeChange"
                          @change="pageChange" />
          </div>
        </div>
      </div>

      <div class="ErrorAdd_right_bottom">
        <div class="ErrorAdd_right_title">错题每日被添加数量</div>
        <div>
          <div class="top_cont">
            <div class="EduRec-top-input">
              <span>日期：</span>
              <a-range-picker v-model="datePickerPrevious" style="margin-right: 15px;width: 220px" @change="ErrorAddOnChange" />
            </div>
            <div class="EduRec-top-input">
              <a-button style="margin-right: 20px" @click="ErrorAdd_exportModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出该模块
              </a-button>
              <a-button  @click="ErrorAdd_exportAllModule" >
                <a-icon style="color: #00A854;" type="file-excel"/>
                导出全部模块
              </a-button>
            </div>
          </div>
        </div>
        <div class="activity-table">
          <!--            :rowKey="record=>record.id"   -->
          <a-table
              :rowKey="record=>record.id"
              @change="orderErrorAddChange"
              :pagination="false"
              :columns="ErrorAdd_columns"
              :data-source="ErrorAdd_data">
          <span slot="location" slot-scope="text, record">
          </span>
          </a-table>
          <!--分页-->
          <div class="pageView">
          <span class="pageLeft">共 {{ ErrorAdd_pagination.total }} 条记录 第 {{
              ErrorAdd_pagination.current
            }} / {{ Math.ceil(ErrorAdd_pagination.total / ErrorAdd_pagination.pageSize) }} 页</span>
            <a-pagination v-model="ErrorAdd_pagination.current"
                          :total="ErrorAdd_pagination.total"
                          :page-size-options="pageSizeOptions"
                          :page-size.sync="ErrorAdd_pagination.pageSize"
                          show-size-changer show-quick-jumper
                          @showSizeChange="ErrorAdd_showSizeChange"
                          @change="ErrorAdd_pageChange" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  getModuleMenu,
  getMistakesAddUser,
  getMistakesAddDay,
  MistakesAddUserExport, MistakesAddDayExport,
} from "@/service/titleExam_api";

const columns = [
  {
    title: '姓名',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: "6%"
  },{
    title: '医院',
    dataIndex: 'hospitalName',
    key: 'hospitalName',
    align: 'center',
    ellipsis: true,
    width:'20%'
  },
  {
    title: '科室',
    dataIndex: 'hospitalOffices',
    key: 'hospitalOffices',
    align: 'center',
    ellipsis: true,
    width:'8%'
  },
  {
    title: '职称',
    dataIndex: 'jobTitle',
    key: 'jobTitle',
    align: 'center',
    ellipsis: true,
    width:'8%'
  },
  {
    title: '日期',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    width: '13%'
  },
  {
    title: '添加错题数量',
    dataIndex: 'questionCount',
    key: 'questionCount',
    align: 'center',
    width: '10%',
    sorter: true
  },
];
const ErrorAdd_columns = [
  {
    title: '题目名称',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    ellipsis: true,
    width: "30%"
  },
  {
    title: '日期',
    dataIndex: 'createdDate',
    key: 'createdDate',
    align: 'center',
    width: '13%'
  },{
    title: '添加人数',
    dataIndex: 'userNumber',
    key: 'userNumber',
    align: 'center',
    width: '10%',
    sorter: true
  },
];
let data = [];
const ErrorAdd_data = [];
export default {
  name: "ErrorAddRecord",
  data(){
    return{
      selectId: [],
      selectTemName:'',
      templateList: [],
      pagination:{
        total:0,
        pageSize: 5,
        current:1,
      },
      spinning:false,
      data,
      ErrorAdd_data,
      columns,
      ErrorAdd_columns,
      startTime:null,
      endTime:null,
      ErrorAdd_pagination:{
        total:0,
        pageSize: 5,
        current:1,
      },
      ErrorAdd_startTime:null,
      ErrorAdd_endTime:null,
      temPosition:{
        pageNum:1,
        pageSize:15,
        total:0,
      },
      order:null,
      orderErrorAdd:null,

      datePicker:null,
      datePickerPrevious:null,

      pageSizeOptions: ['5', '10', '20', '30', '40', '50'],
    }
  },
  async created() {
    await this.getLeftTemplate(); //获取左侧菜单列表
    await this.NumberQuestionsDay(); //用户每日添加错题数量
    await this.NumberUsersErrorAddExercises(); //错题每日被添加数量
  },
  methods:{
    async menuPageChange(current, pageSize) {
      this.temPosition.pageNum = current;
      await this.getLeftTemplate();
      await this.NumberQuestionsDay(); //用户每日添加错题数量
      await this.NumberUsersErrorAddExercises(); //错题每日被添加数量
    },
    //获取左侧菜单列表
    async getLeftTemplate() {
      let data = {
        page:this.temPosition.pageNum,
        pageSize:this.temPosition.pageSize
      }
      const res = await getModuleMenu(data);
      if (res.code === 200) {
        this.templateList = res.data;
        this.selectId[0] = res.data[0].id;
        this.selectTemName = res.data[0].name;
        this.temPosition.total = res.count;
      }
    },
    //获取右侧  用户每日添加错题数量
    async NumberQuestionsDay() {
      let data = {
        typeId:this.selectId[0], //模块id
        startTime:this.startTime,
        endTime:this.endTime,
        curPage:this.pagination.current,
        pageSize:this.pagination.pageSize,
        sortType:this.order, //question_up根据问题数量升序，question_down根据问题数量降序
      }
      const res = await getMistakesAddUser(data);
      if (res.statuscode === 200) {
        this.data = res.data;
        this.pagination.total = res.count;
      }
    },
    //获取右侧  错题每日被添加数量
    async NumberUsersErrorAddExercises() {
      let data = {
        typeId:this.selectId[0], //模块id
        startTime:this.ErrorAdd_startTime,
        endTime:this.ErrorAdd_endTime,
        curPage:this.ErrorAdd_pagination.current,
        pageSize:this.ErrorAdd_pagination.pageSize,
        sortType:this.orderErrorAdd, //question_down问题数量降序，question_up升序，user_up使用人数升序，user_down降序
      }
      const res = await getMistakesAddDay(data);
      if (res.code === 0) {
        this.ErrorAdd_data = res.data;
        this.ErrorAdd_pagination.total = res.count;
      }
    },
    // 第一个表格排序
    orderChange(page, filters, sorter) {
      //获取排序规则 order --- 升序ascend   降序descend;  key  --  columnKey
      if(sorter.columnKey === 'questionCount'){ // 完成题目数量
        //question_up根据问题数量升序，question_down根据问题数量降序
        if(sorter.order === 'ascend'){ //降序

          this.order = 'question_down';

        }else if(sorter.order === 'descend'){ //降序

          this.order = 'question_up';

        }else {
          this.order = null;
        }
        this.pagination.current = 1;
        this.NumberQuestionsDay();
      }
    },
    // 第二个表格排序
    orderErrorAddChange(page, filters, sorter){
      //获取排序规则 order --- 升序ascend   降序descend;  key  --  columnKey
      //question_down问题数量降序，question_up升序，user_up使用人数升序，user_down降序
      if(sorter.columnKey === 'userNumber'){ //添加人数
        if(sorter.order === 'ascend'){ //升序

          this.orderErrorAdd = 'user_down';

        }else if(sorter.order === 'descend'){ //降序

          this.orderErrorAdd = 'user_up';

        }else {
          this.orderErrorAdd = null;
        }
      }else {
          this.orderErrorAdd = null;
      }
      this.ErrorAdd_pagination.current = 1;
      this.NumberUsersErrorAddExercises();
    },
    selectStatus(value,name) {
      this.selectId[0] = value;
      this.selectTemName = name;
      this.pagination.current = 1;
      this.pagination.pageSize = 5;

      this.ErrorAdd_pagination.current = 1;
      this.ErrorAdd_pagination.pageSize = 5;

      this.startTime = null;
      this.endTime = null;
      this.Previous_startTime = null;
      this.Previous_endTime = null;
      this.datePicker = null;
      this.datePickerPrevious = null;

      //切换菜单右侧列表切换内容
      this.NumberQuestionsDay();
      this.NumberUsersErrorAddExercises();
    },

    showSizeChange(current, pageSize) {
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    pageChange(pageNum,pageSize){
      this.pagination.current = pageNum;
      this.pagination.pageSize = pageSize;
      this.NumberQuestionsDay();
    },
    onChange(date, dateString) {
      this.startTime = dateString[0];
      this.endTime = dateString[1];
      this.pagination.current = 1;
      this.NumberQuestionsDay();
    },
    async exportModule() { //导出-同步练习-用户每日完成题目数量   --- 单个模块
      let ExelName = this.selectTemName;
      let data = {
        startTime: this.startTime,
        endTime: this.endTime,
        typeId:this.selectId[0],
        sortType:this.order,
        ExelName: ExelName
      }
      await MistakesAddUserExport(data);
    },
    async exportAllModule() { //导出-同步练习-用户每日完成题目数量  --- 导出全部
      let ExelName = '错题添加记录-用户每日添加错题数量';
      let data = {
        ExelName: ExelName,
        sortType:this.order,
      }
      await MistakesAddUserExport(data);
    },



    ErrorAdd_showSizeChange(current, pageSize) {
      this.ErrorAdd_pagination.current = current;
      this.ErrorAdd_pagination.pageSize = pageSize;
      this.NumberUsersErrorAddExercises();
    },
    ErrorAdd_pageChange(pageNum,pageSize){
      this.ErrorAdd_pagination.current = pageNum;
      this.ErrorAdd_pagination.pageSize = pageSize;
      this.NumberUsersErrorAddExercises();
    },
    ErrorAddOnChange(date, dateString) {
      this.ErrorAdd_startTime = dateString[0];
      this.ErrorAdd_endTime = dateString[1];
      this.ErrorAdd_pagination.current = 1;
      this.NumberUsersErrorAddExercises();
    },
    async ErrorAdd_exportModule() {
      let ExelName = this.selectTemName;
      let data = {
        startTime: this.ErrorAdd_startTime,
        endTime: this.ErrorAdd_endTime,
        typeId: this.selectId[0],
        sortType: this.orderErrorAdd,
        ExelName: ExelName
      }
      await MistakesAddDayExport(data);
    },
    async ErrorAdd_exportAllModule() {
      let ExelName = '错题添加记录-错题每日被添加数量';
      let data = {
        sortType: this.orderErrorAdd,
        ExelName: ExelName
      }
      await MistakesAddDayExport(data);
    },
  }
}
</script>

<style scoped lang="scss">
.ant-menu{
  border: none;
}
.ErrorAddRecord{
  min-height: 110%;
  display: flex;
  justify-content: space-between;
  .ErrorAdd_left{
    width: 20%;
    //background-color: #ee7474;
    .ErrorAdd_module{
      margin-left: 25px;
      margin-top: 10px;
    }

  }
  .ErrorAdd_right{
    border-left: 15px solid #F0F2F5;
    width: 80%;
    .ErrorAdd_right_top{
      border-bottom: 15px solid #F0F2F5;
    }
    .ErrorAdd_right_title{
      width: 98%;
      margin: auto;
      font-weight: bold;
      color: #333333;
      padding-top: 15px;
    }
    .top_cont{
      width: 98%;
      margin: auto;
      display: flex;
      justify-content: space-between;
    }
    .EduRec-top-input{
      height: 50px;
      padding-top: 15px;
    }

    .activity-table{
      width: 98%;
      margin: auto;
      padding-top: 20px;
    }
    .pageView{
      display: flex;
      justify-content: space-between;
      z-index: 3;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .pageLeft{
      font-size: 15px;color: #929292
    }
  }
}
</style>



